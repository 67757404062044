import Input from "components/OldInput";
import PackageAggregate from "components/PackageAggregate";
import { getCountryName, isMemberOfEU } from "constants/countryList";
import Context from "context/Global";
import { getTransporterLogo } from "helpers/Logos";
import { match } from "helpers/Match";
import { getProductName, getTransporterName } from "helpers/Services";
import { priceParser } from "helpers/StringParser";
import moment from "moment";
import React, { Component } from "react";
import nl2br from "react-nl2br";
import { Link } from "react-router-dom";
import Api from "services/Api/Api";
import styled from "styled-components/macro";
import { getIntegrationName } from "../../../../helpers/Integrations";
import { calculateInsurancePrice } from "helpers/PostNord";
import { getTotalAmountOfPackages } from "helpers/TotalPackages";
import { PN_MARGIN_MULTIPLIER } from "constants/carriers/postNord";

let Container = styled.div`
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: flex-start;

    > .header {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1rem;

        > .trackingNumber,
        > .transporter {
            display: flex;
            flex-direction: column;

            > .label {
                font-size: 12px;
                color: #555;
                font-weight: bold;
            }

            > .service,
            .reference {
                font-size: 12px;
                color: #555;
                font-weight: bold;
            }

            > .value {
                font-size: 26px;
                color: #000;
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 1rem;

                > .transporter {
                    height: 50px;
                    padding: 8px 0;
                    min-width: 10px;
                    max-width: 100px;
                }

                > .number {
                    padding-bottom: 4px;
                    white-space: nowrap;
                }

                > .agreement {
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    > img {
                        height: 100%;
                    }
                }
            }
        }

        > .price {
            margin-left: auto;
            padding: 4px 24px;

            > .amount {
                font-size: 38px;
                display: inline-block;
                margin-right: 8px;
            }

            > .unit {
                font-size: 22px;
                display: inline-block;
            }
        }
    }

    > .information {
        margin-bottom: 1rem;
    }
`;

let Route = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
    flex-wrap: wrap;

    > .party {
        margin-right: 60px;

        > .title {
            color: rgba(0, 0, 0, 0.6);
        }

        > .company {
            font-weight: bold;
        }
    }
`;

let Earnings = styled.div`
    margin-bottom: 16px;
    border-collapse: collapse;
    background: rgba(255, 255, 0, 0.1);
    padding: 7px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 16px;

    > .title {
        font-weight: bold;
        color: #555;
    }

    .emissionReport {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .customer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .change {
            width: 16px;
            height: 16px;
            margin-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    table {
        th {
            text-align: left;
            font-weight: normal;
            padding-right: 8px;
            vertical-align: top;
        }

        td {
            text-align: right;

            .invoices {
                > .invoice + .invoice {
                    display: block;
                }
            }
        }

        tr.result {
            th,
            td {
                border-top: 1px solid #aaa;
                padding-bottom: 1rem;
            }

            td {
                color: rgba(20, 150, 20, 1);
                font-weight: bold;
            }

            &.is-negative {
                td {
                    color: rgba(200, 20, 20, 1);
                }
            }
        }
    }
`;

let PackagesTable = styled.table`
    margin-bottom: 16px;
    width: 100%;
`;

class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeCustomer: false,
            customers: null,
            emissionReport: null,
            distance: props.order.distance,
            emissions: props.order.emissions,
            creatingEmissionReport: false,
        };
        this.changeCustomer = this.changeCustomer.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.getOurInsuranceCost = this.getOurInsuranceCost.bind(this);
        this.getCustomerInsurancePrice =
            this.getCustomerInsurancePrice.bind(this);
        this.createEmissionReport = this.createEmissionReport.bind(this);
        this.isZendrAgreement = this.isZendrAgreement.bind(this);
    }

    getOurInsuranceCost() {
        if (this.props.order.insuranceValue) {
            switch (this.props.order.service.name.toLowerCase()) {
                case "fedex": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost <= 79) {
                        return 79;
                    }
                    return cost;
                }
                case "postnord": {
                    const cost =
                        calculateInsurancePrice(
                            this.props.order.insuranceValue,
                        ) * getTotalAmountOfPackages(this.props.order.packages);

                    return cost;
                }
                case "tnt": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost < 130) {
                        return 130;
                    }
                    return cost;
                }
                case "ups": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost < 85) {
                        return 85;
                    }
                    return cost;
                }
                case "dhl": {
                    if (this.props.order.insuranceValue <= 5000) {
                        return 30;
                    } else if (this.props.order.insuranceValue <= 10000) {
                        return 50;
                    } else if (this.props.order.insuranceValue <= 25000) {
                        return 90;
                    } else if (this.props.order.insuranceValue <= 50000) {
                        return 125;
                    } else if (this.props.order.insuranceValue <= 75000) {
                        return 175;
                    } else if (this.props.order.insuranceValue <= 100000) {
                        return 210;
                    } else {
                        return Math.ceil(
                            this.props.order.insuranceValue * 0.0023,
                        );
                    }
                }
            }
            return 0;
        } else {
            return 0;
        }
    }

    getCustomerInsurancePrice() {
        if (this.props.order.insuranceValue) {
            switch (this.props.order.service.name.toLowerCase()) {
                case "fedex": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost <= 79) {
                        return 89;
                    }
                    return cost * 1.2;
                }
                case "postnord": {
                    const cost =
                        calculateInsurancePrice(
                            this.props.order.insuranceValue,
                        ) * getTotalAmountOfPackages(this.props.order.packages);
                    return Math.ceil(cost * PN_MARGIN_MULTIPLIER);
                }
                case "tnt": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost <= 130) {
                        return 159;
                    }
                    return cost * 1.2;
                }
                case "ups": {
                    const cost = Math.ceil(
                        this.props.order.insuranceValue * 0.01,
                    );
                    if (cost <= 85) {
                        return 95;
                    }
                    return cost * 1.2;
                }
                case "dhl": {
                    if (this.props.order.insuranceValue <= 5000) {
                        return 49;
                    } else if (this.props.order.insuranceValue <= 10000) {
                        return 69;
                    } else if (this.props.order.insuranceValue <= 25000) {
                        return 109;
                    } else if (this.props.order.insuranceValue <= 50000) {
                        return 154;
                    } else if (this.props.order.insuranceValue <= 75000) {
                        return 204;
                    } else if (this.props.order.insuranceValue <= 100000) {
                        return 239;
                    } else {
                        return (
                            Math.ceil(
                                this.props.order.insuranceValue * 0.0023,
                            ) * 2
                        );
                    }
                }
            }
            return 0;
        } else {
            return 0;
        }
    }

    getEarnings() {
        return Math.round(
            this.props.order.price.amount -
                this.props.order.price.transportCost -
                this.props.order.price.partnerFee -
                this.getCustomerInsurancePrice() +
                this.getOurInsuranceCost(),
        );
    }

    getFinalEarnings() {
        return Math.round(
            this.props.order.finalPrice.amount -
                this.props.order.finalPrice.transportCost -
                this.props.order.finalPrice.partnerFee -
                this.getCustomerInsurancePrice() +
                this.getOurInsuranceCost(),
        );
    }

    async changeCustomer() {
        this.setState({
            changeCustomer: true,
        });

        // Fetch customers
        this.setState({
            customers: await Api.getCustomers(),
        });
    }

    async selectCustomer(customerId) {
        await Api.moveOrder({
            orderId: this.props.order.id,
            customerId: customerId,
        });

        this.props.refresh();

        this.setState({
            changeCustomer: false,
            customers: null,
        });
    }

    prettyType(type) {
        switch (type) {
            case "pallet":
                return "Pall";
            case "package":
                return "Paket";
            case "envelope":
                return "Envelope";
        }
    }

    async createEmissionReport() {
        this.setState({
            creatingEmissionReport: true,
        });
        try {
            const report = await Api.createEmissionReport({
                orderId: this.props.order.id,
            });

            this.setState({
                emissions: {
                    ...report,
                },
                creatingEmissionReport: false,
            });
            this.props.refresh();
        } catch (error) {
            this.setState({
                creatingEmissionReport: false,
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            distance: props.order.distance,
        };
    }

    getPartnerLogo() {
        if (this.props.order.service.partner === "leman") {
            return "/images/logos/leman-logo.svg";
        } else if (this.props.order.service.partner === "got") {
            return "/images/logos/got-logo.png";
        } else if (
            this.props.order.service.name.toUpperCase() === "UPS" &&
            !this.isZendrAgreement()
        ) {
            return "/images/logos/got-logo.png";
        } else if (
            this.props.order.service.name.toUpperCase() === "DHL EXPRESS"
        ) {
            return "/images/logos/leman-logo.svg";
        }
        if (this.props.order.service.name.toUpperCase() === "FEDEX") {
            return "/images/logos/got-logo.png";
        }
        return "/images/logos/zendr-logo-hallon.svg";
    }

    getPartnerName() {
        if (this.props.order.service.partner === "leman") {
            return "Leman";
        } else if (this.props.order.service.partner === "got") {
            return "Got";
        }
        if (
            this.props.order.service.name.toUpperCase() === "UPS" &&
            !this.isZendrAgreement()
        ) {
            return "Got";
        }
        if (this.props.order.service.name.toUpperCase() === "DHL EXPRESS") {
            return "Leman";
        }
        if (this.props.order.service.name.toUpperCase() === "FEDEX") {
            return "Got";
        }
    }

    isZendrAgreement() {
        switch (this.props.order.service.name.toUpperCase()) {
            case "FEDEX":
                return false;
            case "DHL":
                return true;
            case "TNT":
                return (
                    this.props.order?.route?.from?.countryCode === "SE" &&
                    this.props.order?.route?.to?.countryCode === "SE"
                );
            case "UPS":
                return (
                    !this.props.order?.trackingNumber ||
                    this.props.order?.trackingNumber?.indexOf("1Z758VV") < 0
                );
        }
        return true;
    }

    getPrettyAdditionalOptionName(type) {
        return match(type, {
            insurance: () => "Försäkring",
            greenFreight: () => "Skicka grönt",
            indoorDelivery: () => "Inbärning",
            preAdviceByDriver: () => "Leveransbesked",
            notification: () => "Avisering",
            tailLiftLoading: () => "Bakgavellift vid lastning",
            tailLiftUnloading: () => "Bakgavellift vid lossning",
            thermoWarm: () => "Termo Värme",
        });
    }

    render() {
        return (
            <Container>
                <div className="header">
                    <div className="trackingNumber">
                        <div className="label">
                            Transportör och trackingnummer
                        </div>
                        <div className="value">
                            <img
                                className="transporter"
                                src={`/images/icons/${getTransporterLogo(
                                    this.props.order.service.name,
                                )}`}
                            />
                            <div className="number">
                                {this.props.order.trackingNumber ||
                                    "Bokningen misslyckades"}
                            </div>
                            {(this.context.user.administrator ||
                                (this.context.user.brand &&
                                    this.context.user.brand.id)) && (
                                <div className="agreement">
                                    <img src={this.getPartnerLogo()} />
                                </div>
                            )}
                        </div>
                        <div className="service">
                            {getTransporterName(this.props.order)}{" "}
                            {getProductName(this.props.order)}{" "}
                            {this.props.order.customerIntegrationType
                                ? ` | ${getIntegrationName(
                                      this.props.order.customerIntegrationType,
                                  )}`
                                : null}
                        </div>
                        {this.props.order.information.reference && (
                            <div className="reference">
                                Referens:{" "}
                                {this.props.order.information.reference}
                            </div>
                        )}
                        {this.props.order.state !== "DELIVERED" &&
                            this.props.order.deliveryDate && (
                                <div className="label">
                                    Preliminär leverans:{" "}
                                    {moment(
                                        this.props.order.deliveryDate,
                                    ).calendar(null, {
                                        nextWeek: "[På] dddd",
                                        nextDay: "[Imorgon]",
                                        sameDay: "[Idag]",
                                        lastDay: "[Igår]",
                                        lastWeek: "[I] dddd[s]",
                                        sameElse: "LL",
                                    })}
                                    {this.props.order.service.name ===
                                        "PostNord" &&
                                        !this.props.order.pickup
                                            ?.confirmationNumber &&
                                        `${" "} om upphämtning bokas idag`}
                                </div>
                            )}
                        <div className="label">
                            Bokningen skapades:{" "}
                            {moment(this.props.order.created).calendar(null, {
                                nextWeek: "[På] dddd",
                                nextDay: "[Imorgon]",
                                sameDay: "[Idag] HH:mm",
                                lastDay: "[Igår] HH:mm",
                                lastWeek: "[I] dddd[s]",
                                sameElse: "LL",
                            })}
                        </div>
                        {this.props.order.receiverPays && (
                            <div>Mottagarfrakt</div>
                        )}
                        {this.props.order.pickup?.confirmationNumber && (
                            <div className="label">
                                Bokningsnummer på upphämtning:{" "}
                                {this.props.order.pickup.confirmationNumber}
                            </div>
                        )}
                        {this.props.order.additionalOptions &&
                            this.props.order.additionalOptions
                                .filter((option) => {
                                    // This makes me want to cry but it makes sure that the notification settings are not shown as an additional option
                                    return (
                                        option.value &&
                                        option.name !== "notificationSettings"
                                    );
                                })
                                .map((option) => {
                                    return (
                                        <div
                                            className="label"
                                            key={option.name}
                                        >
                                            Tillägg:{" "}
                                            {this.getPrettyAdditionalOptionName(
                                                option.name,
                                            ) || option?.name}
                                        </div>
                                    );
                                })}
                    </div>
                    {!this.props.order.receiverPays && (
                        <div className="price">
                            <div className="amount">
                                {this.props.order.finalPrice
                                    ? this.props.order.finalPrice.amount
                                    : this.props.order.price.amount}
                            </div>
                            <div className="unit">
                                {this.props.order.price.unit}
                            </div>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-2 gap-4 p-4">
                    {(this.context.user.administrator ||
                        (this.context.user.brand &&
                            this.context.user.brand.id)) && (
                        <Earnings>
                            <div className="title">
                                Information till administratörer
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Kund:</th>
                                        <td>
                                            {!this.state.changeCustomer && (
                                                <div className="customer">
                                                    <div
                                                        className="change"
                                                        onClick={
                                                            this.changeCustomer
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <Link
                                                        to={`/admin/customers/${this.props.order.customer.id}`}
                                                    >
                                                        {
                                                            this.props.order
                                                                .customer.name
                                                        }
                                                    </Link>
                                                </div>
                                            )}
                                            {this.state.changeCustomer && (
                                                <>
                                                    {this.state.customers && (
                                                        <div className="customer">
                                                            <Input
                                                                type="dropdown"
                                                                onChange={
                                                                    this
                                                                        .selectCustomer
                                                                }
                                                                value={
                                                                    this.props
                                                                        .order
                                                                        .customer
                                                                        .id
                                                                }
                                                                options={this.state.customers.map(
                                                                    (
                                                                        customer,
                                                                    ) => {
                                                                        return {
                                                                            title: customer.name,
                                                                            value: customer.id,
                                                                        };
                                                                    },
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                    {!this.state.customers && (
                                                        <div className="customer">
                                                            Loading customers...
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    {this.props.order.finalPrice &&
                                        !this.props.order.receiverPays && (
                                            <>
                                                <tr>
                                                    <th>Fraktkostnad:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .finalPrice
                                                                .transportCost -
                                                                this.getOurInsuranceCost(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Partnerkostnad:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .finalPrice
                                                                .partnerFee,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Kundens pris:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .finalPrice
                                                                .amount -
                                                                this.getCustomerInsurancePrice(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    className={
                                                        `result` +
                                                        (this.getFinalEarnings() <
                                                        0
                                                            ? " is-negative"
                                                            : "")
                                                    }
                                                >
                                                    <th>Vinst transport:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.getFinalEarnings(),
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    {!this.props.order.finalPrice &&
                                        !this.props.order.receiverPays && (
                                            <>
                                                <tr>
                                                    <th>
                                                        Preliminär fraktkostnad:
                                                    </th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .price
                                                                .transportCost -
                                                                this.getOurInsuranceCost(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Partnerkostnad:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .price
                                                                .partnerFee,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Kundens pris:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.props.order
                                                                .price.amount -
                                                                this.getCustomerInsurancePrice(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    className={
                                                        `result` +
                                                        (this.getEarnings() < 0
                                                            ? " is-negative"
                                                            : "")
                                                    }
                                                >
                                                    <th>
                                                        Preliminär vinst
                                                        transport:
                                                    </th>
                                                    <td>
                                                        {priceParser(
                                                            this.getEarnings(),
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    {this.props.order.insuranceValue &&
                                        !this.props.order.receiverPays && (
                                            <>
                                                <tr>
                                                    <th>Försäkringskostnad:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.getOurInsuranceCost(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Kundens pris:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.getCustomerInsurancePrice(),
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className={`result`}>
                                                    <th>Vinst försärking:</th>
                                                    <td>
                                                        {priceParser(
                                                            this.getCustomerInsurancePrice() -
                                                                this.getOurInsuranceCost(),
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    {(this.context.user.administrator ||
                                        ["OWNER", "SUPPORT"].indexOf(
                                            this.context.user.role,
                                        ) >= 0) &&
                                        !this.props.order.receiverPays && (
                                            <tr>
                                                <th>Fakturor</th>
                                                <td>
                                                    {!!this.props.order.invoices
                                                        ?.length && (
                                                        <div className="invoices">
                                                            {this.props.order.invoices.map(
                                                                (invoice) => {
                                                                    return (
                                                                        <Link
                                                                            key={
                                                                                invoice.id
                                                                            }
                                                                            to={`/invoices/${invoice.id}`}
                                                                            className="invoice"
                                                                        >
                                                                            #
                                                                            {
                                                                                invoice.externalId
                                                                            }
                                                                        </Link>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                    )}
                                                    {!this.props.order.invoices
                                                        ?.length &&
                                                        "Ej fakturerad"}
                                                </td>
                                            </tr>
                                        )}
                                    <tr>
                                        <th>Transportör:</th>
                                        <td>
                                            {getTransporterName(
                                                this.props.order,
                                            )}
                                        </td>
                                    </tr>

                                    {!isMemberOfEU(
                                        this.props.order?.route?.from
                                            ?.countryCode,
                                    ) ||
                                        (!isMemberOfEU(
                                            this.props.order?.route?.to
                                                ?.countryCode,
                                        ) && (
                                            <tr>
                                                <th>Tullvilkor:</th>
                                                <td>
                                                    {this.props.order
                                                        ?.information
                                                        ?.customsTerms || "N/A"}
                                                </td>
                                            </tr>
                                        ))}

                                    <tr>
                                        <th>Priskod:</th>
                                        <td>
                                            {
                                                this.props.order.service
                                                    ?.priceCode
                                            }
                                        </td>
                                    </tr>
                                    {this.getPartnerName() && (
                                        <tr>
                                            <th>Partner:</th>
                                            <td>{this.getPartnerName()}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>Utsläpp:</th>
                                        <td>
                                            {!!this.state.emissions && (
                                                <ul className="emissionReport">
                                                    {typeof this.state
                                                        .distance ===
                                                        "number" && (
                                                        <li>
                                                            Avstånd:{" "}
                                                            {Math.round(
                                                                this.state
                                                                    .distance *
                                                                    100,
                                                            ) / 100}{" "}
                                                            km
                                                        </li>
                                                    )}
                                                    {Object.keys(
                                                        this.state.emissions,
                                                    )
                                                        .sort()
                                                        .map((key) => {
                                                            if (
                                                                key ===
                                                                "transportationType"
                                                            ) {
                                                                return (
                                                                    <li>
                                                                        Typ av
                                                                        transport:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .emissions[
                                                                                key
                                                                            ]
                                                                        }
                                                                    </li>
                                                                );
                                                            }
                                                            return (
                                                                <li>
                                                                    {key}:{" "}
                                                                    {Math.round(
                                                                        this
                                                                            .state
                                                                            .emissions[
                                                                            key
                                                                        ]
                                                                            .total *
                                                                            100,
                                                                    ) /
                                                                        100}{" "}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .emissions[
                                                                            key
                                                                        ].unit
                                                                    }
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            )}
                                            <button
                                                className="c-button c-button--raised"
                                                disabled={
                                                    this.state
                                                        .creatingEmissionReport
                                                }
                                                onClick={
                                                    this.createEmissionReport
                                                }
                                            >
                                                Skapa utsläpps-rapport
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Earnings>
                    )}
                    {this.props.order.itemInfo && (
                        <div className="p-2 border border-gray-300 rounded">
                            <div className="title font-bold mb-2">
                                Trackingnummer
                            </div>
                            <div>
                                {this.props.order.itemInfo.map((item) => (
                                    <div key={item.id} className="mb-1">
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500"
                                        >
                                            {item.id}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {this.props.order.insuranceValue && (
                    <div className="information">
                        {this.props.order.insuranceValue &&
                            !this.props.order.receiverPays && (
                                <div className="insurance">
                                    Totalt försäkringsvärde:{" "}
                                    {priceParser(
                                        this.props.order.insuranceValue,
                                    )}{" "}
                                    SEK
                                </div>
                            )}
                    </div>
                )}
                <Route>
                    <div className="party">
                        <div className="title">Avsändare</div>
                        <div className="company">
                            {this.props.order.route.from.contact.private
                                ? this.props.order.route.from.contact.name
                                : this.props.order.route.from.contact.company}
                        </div>
                        {this.props.order.route.from.addressLine1 && (
                            <div className="addressLine">
                                {this.props.order.route.from.addressLine1}
                            </div>
                        )}
                        {this.props.order.route.from.addressLine2 && (
                            <div className="addressLine">
                                {this.props.order.route.from.addressLine2}
                            </div>
                        )}
                        {this.props.order.route.from.addressLine3 && (
                            <div className="addressLine">
                                {this.props.order.route.from.addressLine3}
                            </div>
                        )}
                        {this.props.order.route.from.postalTown && (
                            <div className="postalTown">
                                {[
                                    this.props.order.route.from.postalCode,
                                    this.props.order.route.from.postalTown,
                                ].join(", ")}
                            </div>
                        )}
                        {this.props.order.route.from.countryCode && (
                            <div className="country">
                                {getCountryName(
                                    this.props.order.route.from.countryCode,
                                )}
                            </div>
                        )}
                        {this.props.order.route.from.contact.name && (
                            <div className="contact">
                                Kontaktperson:{" "}
                                {this.props.order.route.from.contact.name}
                            </div>
                        )}
                        {this.props.order.pickup &&
                            this.props.order.pickup.data && (
                                <>
                                    {this.props.order.pickup.data.pickup &&
                                        this.props.order.pickup.data.pickup
                                            .instructions && (
                                            <div>
                                                Instruktioner vid hämtning:{" "}
                                                {nl2br(
                                                    this.props.order.pickup.data
                                                        .pickup.instructions,
                                                )}
                                            </div>
                                        )}
                                </>
                            )}
                    </div>
                    {this.props.order.route.from.pickup &&
                        this.props.order.route.from.pickup.addressLine1 && (
                            <div className="party">
                                <div className="title">
                                    Annan upphämtningsadress
                                </div>
                                <div className="company">
                                    {this.props.order.route.from.pickup.contact
                                        .private
                                        ? this.props.order.route.from.pickup
                                              .contact.name
                                        : this.props.order.route.from.pickup
                                              .contact.company}
                                </div>
                                {this.props.order.route.from.pickup
                                    .addressLine1 && (
                                    <div className="addressLine">
                                        {
                                            this.props.order.route.from.pickup
                                                .addressLine1
                                        }
                                    </div>
                                )}
                                {this.props.order.route.from.pickup
                                    .addressLine2 && (
                                    <div className="addressLine">
                                        {
                                            this.props.order.route.from.pickup
                                                .addressLine2
                                        }
                                    </div>
                                )}
                                {this.props.order.route.from.pickup
                                    .addressLine3 && (
                                    <div className="addressLine">
                                        {
                                            this.props.order.route.from.pickup
                                                .addressLine3
                                        }
                                    </div>
                                )}
                                {this.props.order.route.from.pickup
                                    .postalTown && (
                                    <div className="postalTown">
                                        {[
                                            this.props.order.route.from.pickup
                                                .postalCode,
                                            this.props.order.route.from.pickup
                                                .postalTown,
                                        ].join(", ")}
                                    </div>
                                )}
                                {this.props.order.route.from.pickup
                                    .countryCode && (
                                    <div className="country">
                                        {getCountryName(
                                            this.props.order.route.from.pickup
                                                .countryCode,
                                        )}
                                    </div>
                                )}
                                {this.props.order.route.from.pickup.contact
                                    .name && (
                                    <div className="contact">
                                        Kontaktperson:{" "}
                                        {
                                            this.props.order.route.from.pickup
                                                .contact.name
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    <div className="party">
                        <div className="title">Mottagare</div>
                        <div className="company">
                            {this.props.order.route.to.contact.private
                                ? this.props.order.route.to.contact.name
                                : this.props.order.route.to.contact.company}
                        </div>
                        {this.props.order.route.to.addressLine1 && (
                            <div className="addressLine">
                                {this.props.order.route.to.addressLine1}
                            </div>
                        )}
                        {this.props.order.route.to.addressLine2 && (
                            <div className="addressLine">
                                {this.props.order.route.to.addressLine2}
                            </div>
                        )}
                        {this.props.order.route.to.addressLine3 && (
                            <div className="addressLine">
                                {this.props.order.route.to.addressLine3}
                            </div>
                        )}
                        {this.props.order.route.to.postalTown && (
                            <div className="postalTown">
                                {[
                                    this.props.order.route.to.postalCode,
                                    this.props.order.route.to.postalTown,
                                ].join(", ")}
                            </div>
                        )}
                        {this.props.order.route.to.countryCode && (
                            <div className="country">
                                {getCountryName(
                                    this.props.order.route.to.countryCode,
                                )}
                            </div>
                        )}
                        {this.props.order.route.to.contact.name && (
                            <div className="contact">
                                Kontaktperson:{" "}
                                {this.props.order.route.to.contact.name}
                            </div>
                        )}
                        {this.props.order.pickup &&
                            this.props.order.pickup.data && (
                                <>
                                    {this.props.order.pickup.data.delivery &&
                                        this.props.order.pickup.data.delivery
                                            .instructions && (
                                            <div>
                                                Instruktioner vid lämning:{" "}
                                                {nl2br(
                                                    this.props.order.pickup.data
                                                        .delivery.instructions,
                                                )}
                                            </div>
                                        )}
                                </>
                            )}
                    </div>
                </Route>
                {this.props.order.packages &&
                    this.props.order.packages.length && (
                        <PackagesTable>
                            <thead>
                                <tr>
                                    <th>Antal</th>
                                    <th>Typ</th>
                                    <th>Dimensioner</th>
                                    <th>Vikt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.order.packages.map(
                                    (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.count} st</td>
                                                <td>
                                                    {this.prettyType(item.type)}
                                                </td>
                                                {item.length > 0 ? (
                                                    <td>
                                                        {item.length}x
                                                        {item.width}x
                                                        {item.height} cm
                                                    </td>
                                                ) : item.volume ? (
                                                    <td>
                                                        {item.volume} m
                                                        <sup>3</sup>
                                                    </td>
                                                ) : (
                                                    <td>-</td>
                                                )}
                                                <td>{item.weight} kg</td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </PackagesTable>
                    )}
                {this.props.order.packages &&
                    this.props.order.packages.length && (
                        <PackageAggregate
                            packages={this.props.order.packages}
                            chargeableWeight={
                                this.props.order.totalChargeableWeight
                            }
                            loadingMeters={this.props.order.totalLoadingMeters}
                        />
                    )}
            </Container>
        );
    }
}

OrderSummary.contextType = Context;

export default OrderSummary;
